<template>
  <base-section id="field-services">
    <v-container fluid>
      <v-row
        v-for="(card, i) in $t(`${specificField}`)"
        :key="i"
        justify="center"
        align="center"
      >
        <v-col
          v-if="card.title"
          cols="12"
          sm="7"
        >
          <base-title
            :title="card.title"
            class="text-uppercase"
            space="1"
          />

          <base-divider color="primary" />
          <base-body
            v-if="card.text"
            :text="card.text"
            class="pre-formatted"
            space="4"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="$te(`${singleDescription}`)"
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          sm="7"
        >
          <base-body
            :text="$t(`${singleDescription}`)"
            class="pre-formatted"
            space="4"
          />
        </v-col>
        <v-col
          cols="12"
          sm="7"
        >
          <v-img
            class="eu"
            :src="require('@/assets/EU_sufinanciranje.jpg')"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import View from '@/views/View'
  export default {
    name: 'Geodesy',
    specificField: null,
    singleDescription: null,
    metaInfo: { title: 'Geodesy' },
    extends: View,
    created () {
      const route = this.$route.meta.option
      this.specificField = route + '.options'
      this.singleDescription = route + '.singleDescription'
    },
  }
</script>

<style scoped>
.pre-formatted {
  white-space: pre-line;
}

.eu {
  min-width: 200px;
  max-width: 800px;
  margin: 0 auto;
}
</style>
